import React, { useState } from "react"
import { useForm } from "react-hook-form"

import firebase from "gatsby-plugin-firebase"
import { FormattedMessage, injectIntl, navigate } from "gatsby-plugin-intl"
import isEmail from "validator/lib/isEmail"

import Layout from "../layouts/layout"
import SEO from "../components/seo"
import Herofooter from "../components/Herofooter"

const ContactPage = ({ intl }) => {
  const required = "required"

  const [submitted, setSubmitted, data, setData] = useState(false)
  const {
    register,
    handleSubmit,
    setError,
    errors,
    reset,
    formState: { isSubmitting },
  } = useForm({
    mode: "onBlur",
    shouldFocusError: true,
  })

  const styles = {
    successHeader: {
      color: "inherit",
    },
    input: {
      width: "100%",
    },
  }

  const onSubmit = async data => {
    const dtime = firebase.database.ServerValue.TIMESTAMP
    const ref = firebase.database().ref("webMessages")
    const newKey = ref.push().key
    ref
      .child(newKey)
      .set({
        date: dtime,
        firstName: data.fname,
        lastName: data.lname,
        email: data.email,
        comments: data.message,
      })
      .then(() => {
        setSubmitted(true)
        reset()
      })
      .catch(error => {
        setError("submit", {
          type: "submitError",
          message: `${error.message}`,
        })
        //reset()
      })
  }

  const showSubmitError = msg => <p className="msg-error">{msg}</p>

  const showThankYou = (
    <div className="msg-confirm">
      <h2 style={styles.successHeader}>
        Your message has been sent successfully
      </h2>
      <p>
        We appreciate you contacting us. We will get back in touch with you as
        soon as possible!
      </p>
    </div>
  )

  const showForm = (
    <form onSubmit={handleSubmit(onSubmit)} id="contactForm">
      <input
        type="text"
        id="fname"
        name="fname"
        placeholder={intl.formatMessage({ id: "LANDING.FIRST_NAME" })}
        ref={register({ required })}
        disabled={isSubmitting}
        className={errors.fname ? "warning" : null}
      />

      <input
        type="text"
        id="lname"
        name="lname"
        placeholder={intl.formatMessage({ id: "LANDING.LAST_NAME" })}
        ref={register({ required })}
        disabled={isSubmitting}
        className={errors.lname ? "warning" : null}
      />

      <input
        type="text"
        id="email"
        name="email"
        placeholder={intl.formatMessage({ id: "LANDING.EMAIL" })}
        ref={register({
          required: true,
          validate: input => isEmail(input),
        })}
        disabled={isSubmitting}
        className={errors.email ? "warning" : null}
      />

      <textarea
        id="message"
        name="message"
        placeholder={intl.formatMessage({ id: "LANDING.COMMENTS" })}
        ref={register({ required })}
        disabled={isSubmitting}
        className={errors.message ? "warning" : null}
      ></textarea>

      <button
        type="submit"
        value="Submit"
        className="cta-button cta-button--gray cta-button--text-sky-blue gc-analytics-event"
        disabled={isSubmitting}
      >
        <FormattedMessage id="LANDING.SUBMIT" />
      </button>
    </form>
  )

  return (
    <Layout>
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: "SEO.LANDING.CONTACT.TITLE" })}
        description={intl.formatMessage({
          id: "SEO.LANDING.CONTACT.DESCRIPTION",
        })}
        keywords={intl.formatMessage({ id: "SEO.LANDING.CONTACT.KEYWORDS" })}
      />

      <div id="landing-contact">
        <div className="tpnet-main-content">
          <tpnet-content>
            <article className="tpnet-article">
              <article className="tpnet-article-inner">
                <div className="tpnet-article-body">
                  <main>
                    <section className="landing-header">
                      <div
                        className="landing-header__nacho landing-header__nacho--default"
                        aria-hidden="true"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="3463.97px"
                          height="3947.35px"
                          viewBox="0 0 3463.97 3947.35"
                          preserveAspectRatio="xMinYMin"
                        >
                          <defs>
                            <clipPath id="landing-header-nacho_svg__clip-path">
                              <path
                                className="landing-header-nacho_svg__cls-1"
                                d="M-3593.5-2560.43c286.06-63.44 1878.61 2088.8 1793.43 2324.85s-2266.29 720.51-2442.7 542.47c-26-26.3-36.38-105.87-34.34-222.62 11.69-674.45 439.65-2590.53 683.61-2644.7z"
                              ></path>
                            </clipPath>
                            <clipPath id="landing-header-nacho_svg__clip-path-2">
                              <path
                                fill="none"
                                d="M-5206.6-3363.17h4882v3709h-4882z"
                              ></path>
                            </clipPath>
                            <clipPath id="landing-header-nacho_svg__clip-path-3">
                              <path
                                className="landing-header-nacho_svg__cls-1"
                                d="M33.7 3869.38C259.33 4130.56 3298.85 3677.6 3437.6 3362c20.45-46.59-5.48-153.88-65.65-302.79C3024.47 2198.87 1530.45-50.73 1190.5 0 791.92 59.49-192 3608.24 33.7 3869.38z"
                              ></path>
                            </clipPath>
                          </defs>
                          <g
                            clipPath="url(#landing-header-nacho_svg__clip-path)"
                            id="landing-header-nacho_svg__header-nacho"
                          >
                            <g clipPath="url(#landing-header-nacho_svg__clip-path-2)">
                              <path d="M-6731.56-24594.94l-221.11 27884.15L4247.1 3719.53l221.12-27884.15-11199.78-430.32z"></path>
                            </g>
                          </g>
                          <g
                            clipPath="url(#landing-header-nacho_svg__clip-path-3)"
                            id="landing-header-nacho_svg__landing-header-nacho"
                          >
                            <path
                              transform="rotate(-22 1175.988 2165.688)"
                              fill="#f67f00"
                              d="M-530.36 155.86h3412.69v4019.63H-530.36z"
                            ></path>
                          </g>
                        </svg>
                      </div>
                      <header className="landing-header__text">
                        <div className="landing-header__inner-wrapper">
                          <tpnet-heading className="light">
                            <h1>
                              <FormattedMessage id="LANDING.CONTACT" />
                            </h1>
                          </tpnet-heading>
                          <tpnet-heading className="light">
                            <h2>
                              <FormattedMessage id="LANDING.PAGE_CONTACT.TITLE" />
                            </h2>
                          </tpnet-heading>
                        </div>
                      </header>
                    </section>
                    <div className="content-wrapper">
                      <section className="card">
                        <div className="text-block">
                          <div className="text-side">
                            {errors &&
                              errors.submit &&
                              showSubmitError(errors.submit.message)}
                          </div>

                          <div className="form-side">
                            {submitted ? showThankYou : showForm}
                          </div>
                        </div>
                      </section>
                    </div>

                    <Herofooter />
                  </main>
                </div>
              </article>
            </article>
          </tpnet-content>
        </div>
      </div>
    </Layout>
  )
}

export default injectIntl(ContactPage)
